<script>
// import { ref } from "vue";
import Datepicker from "vue3-datepicker";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { reqMethods } from '../../../state/helpers';

/**
 * Orders component
 */
export default {
    components: {
        Layout,
        PageHeader,
        Datepicker
    },
    data() {
        return {
            picked: "",
            daterange: "",
            alertData: [],
            title: "Liste des reponses",
            items: [
                {
                    text: "Dashboard",
                    href: "/",
                },
                {
                    text: "Reponses",
                    active: true,
                },
            ],
          responseData: [],
          receiveResponsesList: [],
          fetchError: null,
        };
    },
    computed: {
        /**
         * Total no. of records
         */

    },
    mounted() {
      this.getThisAlertResponses();
    },
    methods: {
        ...reqMethods,
        searchFilter(e) {
            const searchStr = e.target.value;
            this.alertData = this.alertData.filter((alert) => {
                return (
                    alert.titre.toLowerCase().includes(searchStr.toLowerCase()) ||
                    alert.thematique.toLowerCase().includes(searchStr.toLowerCase()) ||
                    alert.zone.toLowerCase().includes(searchStr.toLowerCase()) ||
                    alert.titre.toLowerCase().includes(searchStr.toLowerCase())
                );
            });
        },

      getThisAlertResponses(){
        const that = this;
        this.getRequest(`/alert/alert-agent/reply/?id=${this.$route.params.id}`)
            .then(
                function (response) {
                  that.responseData = response.data;
                  that.receiveResponsesList = response.data;
                  console.log( that.responseData);
                },
                function (error) {
                  that.fetchError = error;
                }
            )
      }

    },
};
</script>

<template >
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-between">
                            <div class="col-md-5">
                                <div class="form-group mt-3 mb-0">
                                    <div class="search-box me-2">
                                        <div class="position-relative">
                                            <input type="text" class="form-control bg-soft bg-info border-0"
                                                placeholder="Rechercher..." @input="searchFilter($event)" />
                                            <i class="bx bx-search-alt search-icon"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row col-md-6">
                                <div class="form-group col-md-4 mt-3 mb-0">
                                    <datepicker v-model="picked" :first-day-of-week="1" lang="en" confirm
                                        class="form-control" placeholder="Date"></datepicker>
                                </div>

                                <div class="mt-3 col-md-4 mb-0">
                                    <button class="btn btn-info">
                                        <i class="bx bx-slider align-middle me-1"></i> Lancer le filtre
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive mt-3">
                            <table class="table table-nowrap table-centered mb-0 align-middle">
                                <thead class="table-light">
                                    <tr>
                                        <th>Référencess</th>
                                        <th>Auteur</th>
                                        <th>Téléphone</th>
                                        <th scope="col">Titre d'alerte</th>
                                        <th scope="col">Date</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(alert,index) in responseData" :key=index>
                                        <!-- v-for="response in alertData" :key=alert.id -->
                                        <td> {{ alert?.short_code }} </td>
                                        <td> {{ alert.sender?.name }} {{ alert.sender?.first_name }} </td>
                                       <td v-if="alert.sender?.telephone?.length != 0"> {{ alert.sender.telephone }} </td>
                                        <td> {{ alert.alert_agent?.title }} </td>
                                        <td>{{ alert.created_at.split('T')[0] }} </td>
                                        <td>
                                            <button @click="$router.push({ path: `/agentsResponsDetail/${alert.id}` })"
                                                class="btn btn-success btn-sm me-2 w-xs">
                                                Lire  
                                           </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
